<template>
  <template v-if="phone_numbers.length > 0">
    <h4 class="text-center mb-4">{{ $t('purchasedTabs.phone_number_alias_title') }}</h4>
    <Form :initial-values="formSchema"
          @submit="submit"
          ref="phone_numbers">
      <div class="text-center paymentDetail-param paymentDetail-param_main mb-1">
        {{ $t('forms.senderName.connectPhoneNumberTo') }}:
      </div>
      <div class="text-center">
          <Field
            as="select"
            name="phone_number"
            id="phone_numbers"
            class="form-control mb-0 form-group d-inline-flex w-auto mx-2"
            v-model="selectedPackage"
          >
            <option
              :key="item.id"
              :value="item.id"
              v-for="item of phone_numbers"
            >{{ item.sender_name }}
            </option>
          </Field>
      </div>
      <div v-if="selectedPackageObject" class="font-weight-bold mt-3 text-center paymentDetail-param_plan paymentDetail-param_plan_main">
        {{ selectedPackageObject.phone_number_localized_fee }}
      </div>
      <div v-if="selectedPackageObject" class="font-weight-bold m-0 text-center paymentDetail-monthly_plan paymentDetail-param_plan_main">
        {{$t('forms.buyPackages.monthly')}}
      </div>
      <div class="text-center mt-2 mb-3" v-if="selectedPackageObject.phone_number_setup_localized_fee">
          {{ $t('purchasedTabs.sender_name_fee') }} {{ selectedPackageObject.phone_number_setup_localized_fee }}
      </div>
      <div class="d-flex justify-content-center mt-4">
        <button
          class="btn btn-primary btn-lg shadow-2"
          style="position: relative"
        >
          {{ $t('forms.buyPackages.continue') }}
        </button>
      </div>
    </Form>
    </template>
    <template v-if="phone_numbers.length === 0">
      <h4 class="text-center mb-4">{{ $t('purchasedTabs.phone_number_alias_title') }}</h4>
      <div class="paymentDetail-info">
        <div class="pb-3 d-flex justify-content-between">
          <alert type="info" text="forms.senderName.noActiveSenderNames" class="mb-1 font-weight-bold" />
        </div>
      </div>
    </template>
    <slot></slot>
</template>

<script>

import { Form, Field } from 'vee-validate'
import { computed, defineAsyncComponent, ref } from 'vue'

export default {
  name: 'BuyPhoneNumberAliasForm',
  components: {
    Form,
    Field,
    alert: defineAsyncComponent(() => import('@/application/components/elements/Alert.vue')),
  },
  props: {
    phone_numbers: {
      type: Array,
    },
  },
  emits: ['select-package'],
  setup(props, { emit }) {
    const selectedPackage = ref(props.phone_numbers.length > 0 ? props.phone_numbers[0].id : null)
    const selectedPackageObject = computed(() => {
      const selectedPackageObject = props.phone_numbers.find(i => i.id === selectedPackage.value)
      return selectedPackageObject ? { ...selectedPackageObject } : null
    })
    return {
      submit: async() => {
        emit('select-package', selectedPackageObject)
      },
      selectedPackageObject,
      selectedPackage,
    }
  },
}
</script>
